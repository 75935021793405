<template>
  <div id="chip-group">
    <div v-if="data.length" class="d-flex align-center">
      <v-chip class="mr-2" small>{{ data[0] }}</v-chip>

      <v-tooltip v-if="_moreData" max-width="300px" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-bind="attrs" v-on="on" small>+{{ data.length - 1 }}</v-chip>
        </template>

        <div v-html="_moreData" class="more-data"></div>
      </v-tooltip>
    </div>

    <div v-else>
      <v-chip small>--</v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChipGroup",

  props: {
    data: {
      type: Array,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    _moreData() {
      if (this.data.length <= 1) return;

      const data = structuredClone(this.data);
      data.shift();

      return data.join(", ");
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
