import Vue from "vue";
import VueI18n from "vue-i18n";
import pt from "@/locales/pt.json";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "pt",
  messages: {
    pt: pt,
  },
});

export default i18n;
