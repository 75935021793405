<template>
  <CustomDialog
    ref="customDialog"
    :title="_title"
    :subtitle="_subtitle"
    :footerButtons="_buttons"
    :loading="loading"
    @submit="submit()"
  >
    <v-form v-model="valid" ref="form" class="mt-4">
      <v-text-field
        v-model="form.summary"
        label="Nome do Evento"
        :disabled="_viewMode || loading"
        :rules="_rules"
        outlined
      />

      <v-textarea
        v-model="form.description"
        label="Descrição"
        :disabled="_viewMode || loading"
        rows="3"
        auto-grow
        outlined
      />

      <v-date-picker
        v-model="form.eventDate"
        color="primary"
        width="100%"
        :min="_minDate"
        :disabled="_viewMode || loading"
        no-title
        range
      >
      </v-date-picker>

      <v-checkbox
        v-model="form.allDay"
        label="O dia todo?"
        :disabled="this.user.type !== 'director'"
      />

      <v-row v-if="!form.allDay">
        <v-col cols="6">
          <v-text-field
            v-model="form.startTime"
            v-mask="'##:##'"
            placeholder="HH:MM"
            label="Horário de início"
            prepend-inner-icon="mdi-clock-time-four-outline"
            :rules="_timeRules"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form.endTime"
            v-mask="'##:##'"
            placeholder="HH:MM"
            label="Horário de término"
            prepend-inner-icon="mdi-clock-time-four-outline"
            :rules="_timeRules"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-select
        v-model="form.transparency"
        label="Disponibilidade"
        :items="_transparencies"
        :disabled="_viewMode || loading"
        :rules="_rules"
        item-text="text"
        item-value="value"
        outlined
      />
    </v-form>
  </CustomDialog>
</template>

<script>
import { mapState } from "vuex";

import { createEvent, updateEvent } from "@/services/calendars";
import {
  handleAlert,
  formatDate,
  formatISODate,
  getStartOfMinute,
  getEndOfMinute,
} from "@/utils";
import { mask } from "vue-the-mask";
import CustomDialog from "@/components/customDialog/CustomDialog.vue";
const moment = require("moment");

export default {
  name: "EventDialog",

  components: {
    CustomDialog,
  },

  directives: {
    mask,
  },

  data() {
    return {
      mode: "create",
      valid: false,
      loading: false,

      form: {
        id: "",
        summary: "",
        description: "",
        eventDate: [],
        allDay: true,
        startTime: "",
        endTime: "",
        transparency: "",
      },
    };
  },

  props: {
    calendar: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState(["user"]),

    _transparencies() {
      return this.user.type === "director"
        ? [
            {
              text: "Disponível",
              value: "transparent",
            },
            {
              text: "Indisponível",
              value: "opaque",
            },
          ]
        : [
            {
              text: "Indisponível",
              value: "opaque",
            },
          ];
    },
    _viewMode() {
      return this.mode === "view";
    },

    _editMode() {
      return this.mode === "edit";
    },

    _title() {
      if (this._viewMode) return "Visualizar Evento Extraordinário";
      else if (this._editMode) return "Editar Evento Extraordinário";

      return "Adicionar novo Evento Extraordinário";
    },

    _subtitle() {
      if (this._viewMode) return "";
      if (this._editMode)
        return "Preencha os campos abaixo para editar o Evento";

      return "Preencha os campos abaixo para criar um novo Evento";
    },

    _buttons() {
      if (this._viewMode)
        return [
          {
            label: "Fechar",
            color: "primary",
            action: "close",
          },
        ];

      return [
        {
          label: "Cancelar",
          color: "secondary",
          action: "close",
        },
        {
          label: "Prosseguir",
          color: "primary",
          action: "submit",
        },
      ];
    },

    _minDate() {
      return moment().format("YYYY-MM-DD");
    },

    _rules() {
      return [(v) => !!v || "Campo obrigatório"];
    },

    _timeRules() {
      return [
        (v) => !!v || "Hora é obrigatória",
        (v) =>
          /^([01]\d|2[0-3]):([0-5]\d)$/.test(v) ||
          "Hora deve estar no formato HH:MM e ser válida",
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        if (this._editMode) await this.updateEvent();
        else await this.createEvent();

        this.$emit("success");
        this.closeDialog();
      } catch (error) {
        this.handleAlert(error.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    async createEvent() {
      const eventDate = this.form.eventDate.sort(
        (a, b) => new Date(a) - new Date(b)
      );

      const startDate = eventDate[0];
      const endDate = eventDate.length === 2 ? eventDate[1] : eventDate[0];

      const payload = {
        summary: this.form.summary,
        description: this.form.description,
        start: {
          ...(this.form.allDay
            ? {
                date: startDate,
              }
            : {
                dateTime: formatISODate(
                  getStartOfMinute(`${startDate}T${this.form.startTime}`)
                ),
              }),
          timeZone: this.calendar.timeZone,
        },
        end: {
          ...(this.form.allDay
            ? {
                date: endDate,
              }
            : {
                dateTime: formatISODate(
                  getEndOfMinute(`${endDate}T${this.form.endTime}`)
                ),
              }),
          timeZone: this.calendar.timeZone,
        },
        timeZone: this.calendar.timeZone,
        transparency: this.form.transparency,
      };

      await createEvent(this.calendar.id, payload);

      this.handleAlert("Evento adicionado com sucesso", "success");
    },

    async updateEvent() {
      const eventDate = this.form.eventDate.sort(
        (a, b) => new Date(a) - new Date(b)
      );

      const startDate = eventDate[0];
      const endDate = eventDate.length === 2 ? eventDate[1] : eventDate[0];

      const payload = {
        summary: this.form.summary,
        description: this.form.description,
        start: {
          ...(this.form.allDay
            ? {
                date: startDate,
              }
            : {
                dateTime: formatISODate(
                  getStartOfMinute(`${startDate}T${this.form.startTime}`)
                ),
              }),
          timeZone: this.calendar.timeZone,
        },
        end: {
          ...(this.form.allDay
            ? {
                date: endDate,
              }
            : {
                dateTime: formatISODate(
                  getEndOfMinute(`${endDate}T${this.form.endTime}`)
                ),
              }),
          timeZone: this.calendar.timeZone,
        },
        timeZone: this.calendar.timeZone,
        transparency: this.form.transparency,
      };

      await updateEvent(this.calendar.id, this.form.id, payload);

      this.handleAlert("Evento editado com sucesso", "success");
    },

    openDialog(data, mode) {
      this.mode = mode || "create";

      if (data) {
        this.form = {
          id: data.id,
          summary: data.summary,
          description: data.description,
          eventDate: [
            formatDate(data.start.date || data.start.dateTime, "YYYY-MM-DD"),
            formatDate(data.end.date || data.end.dateTime, "YYYY-MM-DD"),
          ],
          allDay: !!data.start.date && !!data.end.date,
          startTime: data.start.dateTime
            ? formatDate(data.start.dateTime, "HH:mm")
            : "",
          endTime: data.end.dateTime
            ? formatDate(data.end.dateTime, "HH:mm")
            : "",
          transparency: data.transparency || "opaque",
        };
      } else {
        this.resetForm();
      }

      this.$refs.customDialog.open();
    },

    closeDialog() {
      this.resetForm();
      this.$refs.customDialog.close();
    },

    resetForm() {
      this.form.id = "";
      this.form.summary = "";
      this.form.description = "";
      this.form.eventDate = [];
      this.form.allDay = true;
      this.form.startTime = "";
      this.form.endTime = "";
      this.form.transparency = "";

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    handleAlert,
  },
};
</script>

<style src="./style.scss" lang="scss" />
