import { render, staticRenderFns } from "./ChipGroup.vue?vue&type=template&id=b0a4a28a&scoped=true"
import script from "./ChipGroup.vue?vue&type=script&lang=js"
export * from "./ChipGroup.vue?vue&type=script&lang=js"
import style0 from "./style.scss?vue&type=style&index=0&id=b0a4a28a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0a4a28a",
  null
  
)

export default component.exports